import React from 'react'
import { graphql } from 'gatsby'

import styles from './CarGallery.module.css'
import Layout from '../../sections/Layout/Layout'
import { compareByImgName } from '../../lib/comparisons' 
import ZoomableImg from '../../components/ZoomableImg/ZoomableImg'


const imgContainerStyles = {width: '700px', height: '430px', boxShadow: '0 10px 8px -4px black', borderBottom: '5px solid', borderColor: '#052D5A', marginBottom: '3%'};
const imgStyles = {width: '700px', height: '430px'};

const phoneImgContainerStyles = {width: '340px', height: '240px', boxShadow: '0 10px 8px -4px black', borderBottom: '5px solid', borderColor: '#052D5A', marginBottom: '3%'};
const phoneImgStyles = {width: '340px', height: '240px'};


export default function CarGallery({ data }) {
    const { title } = data.markdownRemark.frontmatter;
    const imgs = data.allFile.edges;

    return (
        <Layout>
            <div className={styles.title}>
                <h1>{title}</h1>
            </div>
            <div className={styles.imgsContainer}>
                {
                    imgs.sort(compareByImgName).map(({ node }) => 
                        <>
                            <div className={styles.desktop}>
                                <ZoomableImg 
                                    img={node}
                                    containerStyle={imgContainerStyles}
                                    style={imgStyles}
                                />
                            </div>

                            <div className={styles.mobile}>
                                <ZoomableImg 
                                    img={node}
                                    containerStyle={phoneImgContainerStyles}
                                    style={phoneImgStyles}
                                />
                            </div>
                        </>
                    )
                }
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!, $folder: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                img,
                people
            }
        }
        allFile(filter: {relativeDirectory: {eq: $folder}}) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 1500) {
                    ...GatsbyImageSharpFluid
                  }
                }
                name
              }
            }
        }
    }
`
